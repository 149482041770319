/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import leftIcon from "../images/Left Button.svg";
import rightIcon from "../images/Right Button.svg";
let constValue = 1;
export interface cardModalINFO {
  id: string;
  title?: string;
  content?: object;
  image?: string;
  link?: string;
}
let tmp = 0;

export const GA_carousel: React.FC<PropsWithChildren<cardModalINFO>> = ({
  title,
  content,
}) => {
  const changeSlidedecrement = () => {
    const values = document.getElementById(
      "slidervalue"
    ) as HTMLInputElement | null;
    const selectedvalue = constValue;
    let decrement = selectedvalue;
    tmp = decrement;
    if (decrement != "1") {
      decrement = Number(selectedvalue) - 1;
      constValue = decrement;
      // values.value = decrement;
      window.location.href = "#" + decrement;
      const currentdots = document.getElementById(
        "dot" + decrement
      ) as HTMLInputElement;
      const prevdotsdots = document.getElementById(
        "dot" + selectedvalue
      ) as HTMLInputElement;
      currentdots.style.backgroundColor = "#9c3179";
      prevdotsdots.style.backgroundColor = "#C4C4C4";
    }
  };
  const clicktime = (value: undefined) => {
    const currentdots = document.getElementById(
      "dot" + value
    ) as HTMLInputElement;
    const currentdots1 = document.getElementById(
      "dot" + constValue
    ) as HTMLInputElement;
    currentdots.style.backgroundColor = "#9c3179";
    if (constValue != 0 && constValue != value) {
      constValue = value;
      currentdots1.style.backgroundColor = "#C4C4C4";
    }
  };
  const changeSlideincrement = () => {
    const values = document.getElementById(
      "slidervalue"
    ) as HTMLInputElement | null;
    const selectedvalue1 = constValue;
    let increment = selectedvalue1;
    if (increment < Object.keys(content).length) {
      increment = Number(selectedvalue1) + 1;
      constValue = increment;
      tmp = increment;
      // values.value = increment;
      window.location.href = "#" + increment;
      const currentdots = document.getElementById(
        "dot" + increment
      ) as HTMLInputElement;
      const prevdotsdots = document.getElementById(
        "dot" + selectedvalue1
      ) as HTMLInputElement;
      currentdots.style.backgroundColor = "#9c3179";
      prevdotsdots.style.backgroundColor = "#C4C4C4";
    }
  };

  let leftClassName: string;
  let rightClassName: string;

  if (!title) {
    leftClassName = "";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-4 lg:col-span-8 text-center mt-20px lg:mt-0 p1";
  } else if (title) {
    leftClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
  } else {
    leftClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
  }

  return (
    <section
      data-component="GA_carousel"
      className={classNames("page-grid max-width z-0 bg-white-1")}
    >
      <div className="carousel-container col-span-12 col-start-2">
        {content.map((n) => (
          <div className="carousel-item page-grid max-width z-0" id={n.id}>
            <div className="grid grid-cols-1 md:grid-cols-2 w-full">
              <div className="ga-m-auto">
                <h2
                  className={classNames(
                    leftClassName,
                    "p1 ga-paragraph-title ga-font-style-s24"
                  )}
                >
                  {n.title}
                </h2>
                <h3
                  className={classNames(
                    "col-start-2 col-span-12 lg:col-start-2 lg:col-span-8 lg:row-span-2 mt-20px lg:mt-0 text-right-margin p3 ga-paragraph-content ga-font-style-s16"
                  )}
                >
                  {n.content}
                </h3>
              </div>
              <div className="">
                <img src={n.link} className="ga-image-carusel" />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="col-span-12 col-start-4 md:col-start-8 md:col-strat-8 sm:col-start-4">
        <ul id="link-container">
          <li>
            <button
              className="prev-button col-start-2 row-start-3 lg:row-start-2 lg:col-start-1 lg:col-span-1 h-full justify-self-start lg:justify-self-center"
              onClick={() => changeSlidedecrement()}
            >
              <img src={leftIcon} alt="Left" />
            </button>
            <input type="hidden" id="slidervalue" value="1" />
          </li>
          {content.map((n) => (
            <li>
              <a
                className={classNames("image-link", n.id == 1 ? "ga_dot1" : "")}
                href={`#${n.id}`}
                id={`dot${n.id}`}
                onClick={() => clicktime(n.id)}
              ></a>
            </li>
          ))}
          <li>
            <button
              className="next-button col-start-13 row-start-3 lg:row-start-2 lg:col-start-14 lg:col-span-1 h-full justify-self-end lg:justify-self-center"
              onClick={() => changeSlideincrement()}
            >
              <img src={rightIcon} alt="Right" />
            </button>
          </li>
        </ul>
      </div>
    </section>
  );
};
