/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Hero, Statement, Stories } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
// import { rtfFromText } from "@bond-london/graphcms-rich-text";
import { Researchblock } from "../../components/DownloadBlock";
import { GA_paragraph } from "../../components/GA_paragrph";
import { GA_CardModal } from "../../components/GA_cardModal";
import { GA_carousel } from "../../components/GA_carousel";
import carouselImage1 from "../../images/Bona.jpg";
import carouselImage2 from "../../images/phailantropy.jpg";
import carouselImage3 from "../../images/supporting.jpg";
import socialHead1 from "../../images/social-header-bg-1.png";
import probonowork from "../../images/First-page.jpg";
import { GA_carousel1 } from "../../components/ga_carousel1";

import solarbuddy from "../../images/solarbuddy-front.jpg";
import raysofhope from "../../images/raysofhope-front.jpg";
import handsonnashville from "../../images/handsonnashville-front.jpg";
import oxfam from "../../images/oxfam-front.jpg";
import pobono from "../../images/prono-work.jpg";

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: socialHead1,
      srcSet: socialHead1,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const probonoworkImage = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: probonowork,
      srcSet: probonowork,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="Our Social Values | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="Our Social Values"
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title="We seek to cultivate a sense of  purpose in the workplace and do good in the world."
        headline=""
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                text: "The core Partners in Performance principles are to focus on our pursuit to maximise the value we create collectively as an organisation for our clients, our people and our planet. We value the things that matter and hold strong resolve to 'do the right thing' in bringing positive change across all aspects of our business and to the world we share.",
              },
            ],
          },
          {
            type: "paragraph",
            children: [
              {
                text: "By bringing opportunities and creating space for our people to do meaningful work in a way that feels authentic to them, we help them live their personal values so we can collectively make a lasting, positive impact. ",
              },
            ],
          },
        ]}
        id={""}
        className=""
      />
      <Researchblock
        quote=""
        Title="We are honoured to be a signatory of the United Nations Global Compact a Corporate Social Responsibility initiative."
        Link="/en/corporate-social-responsibility"
      />
      <GA_CardModal
        id={""}
        title={"TEST"}
        content={"TESTContent"}
        Link={"URL"}
      />
      <Researchblock
        quote="This is an important step in formalising our commitment to advance the reconciliation journey in Australia with Aboriginal and Torres Strait Islander peoples."
        Title="We are proud to present our Reconciliation Action Plan (RAP)."
        Link="https://media.graphassets.com/LWjllxNDTeWiWqrUSnbW"
      />
      <GA_paragraph
        id={""}
        title="We are driven to make a lasting impact for the good of all"
        messagez="Our commitment to giving back to the communities we work and live in is at the core of everything we do. We are honoured to work with our clients, our people and our communities to make a positive and lasting impact across the planet. Find out how we live our social values below. "
        className="ga-paragraph-title border-t-grey"
        // messageclass="ga-line-height-36"
        sessiondesign="border-t-2 border-lightgrey1"
      />
      <GA_carousel1
        id={""}
        content={[
          {
            id: "7",
            title: "DOOR OF HOPE ",
            content:
              "Door of Hope is a non-profit organisation saving abandoned babies by finding them safe homes through uniting families, adoption or the Door of Hope Village. While they had a large online following, they struggled to turn their supporters into a consistent donor base. As part of our Corporate Social Investment, our team helped them understand their donors, map out a donor journey and develop a fundraising ‘pitch pack.’",
            content2:
              " This resulted in the integration of a mobile payment app to make donating easier, an improved system to increase funding by 125%, a roadmap to unlock even more donation opportunities, and an internal fundraising event that raised R146,000 (approximately US$9,200) for Doors of Hope.",
            link: carouselImage1,
            // btnLink: "/en/case-study/door-of-hope",
            btnLink: "https://doorofhope.co.za/",
          },
          {
            id: "8",
            title: "SOLARBUDDY",
            content:
              "SolarBuddy is a global impact organisation whose mission is to ensure children living in extreme poverty have access to clean, reliable and safe energy. SolarBuddy provides solar devices to children and inspires people to be agents of change to help end energy poverty by 2030.",
            content2:
              " To live up to our social values, Partners in Performance assembled 200 Solarbuddies in August 2022 and worked with SolarBuddy to impact 1000 lives. Our team provided lights to children living in extreme poverty that are expected to last for 10 years, contributing to 1.45 million extra study hours. We are currently looking for more opportunities to help this great organisation in the future.",
            link: solarbuddy,
            // btnLink: "/en/case-study/solarbuddy",
            btnLink: "https://www.solarbuddy.org/",
          },
          {
            id: "9",
            title: "RAYS OF HOPE ",
            content:
              "Rays of Hope is a non-for-profit organisation that has initiated outreach programs to one of the poorest areas in Alexandra, Johannesburg. Over the last 30 years it has grown to a network of ten community-based programmes, focusing on education, helping vulnerable children and work readiness, supporting over 2,800 people every year. Since its inception, developed close relationships with community leaders, police, schools, The Alexandra Clinic and the Department of Social Development to best support the people of Alexandra. ",
            content2:
              "In July 2022, Partners in Performance supported the Rays of Hope annual #WarmUpAlex campaign by donating blankets, winter clothes, non-perishable food, hot water bottles and more to keep people in the community warm during the winter.",
            link: raysofhope,
            // btnLink: "/en/case-study/rays-of-hope",
            // btnLink: "https://raysofhope.co.za/warmupalex/",
            btnLink: "https://raysofhope.co.za/",
          },
          {
            id: "10",
            title: "PROJECT C.U.R.E – HANDS ON NASHVILLE",
            content:
              "Founded in 1987, Project C.U.R.E. is a healthcare foundation that collects, categorises and distributes medical supplies to healthcare professionals and facilities around the world. It is the world’s largest distributor of donated medical supplies, delivering critical equipment to more than 135 countries. Their donations are collected from hospitals and healthcare providers before being prepared and shipped internationally to people in need.",
            content2:
              " As part of our Corporate Social Investment, 35 members of Partners in Performance volunteered 105 hours of their time, resulting in a $3,000 economic impact that reached over 500 individuals.",
            link: handsonnashville,
            // btnLink: "/en/case-study/cure",
            btnLink: "https://projectcure.org/about-us/",
          },
          {
            id: "11",
            title: "OXFAM TRAILWALKER",
            content:
              "The Oxfam Trailwalker is the ultimate team endurance event where a team of 4 (with support crews) must walk, jog or run 100km in under 30 hours. Proceeds from the race go to Oxfam which supplies, delivers, builds and maintains lifesaving solar-powered water pumps, new farming techniques and portable toilets in hard-to-reach vulnerable communities.",
            content2:
              " Further, funds are also distributed to the Gurkha Welfare Trust to aid Gurkha veterans (soldiers from Nepal whose relationship with the British Army is 200 years old), their widows and wider communities to provide medical clinics, specialist staff, access to education and support for natural disaster crisis management. Our team managed to finish the event in 24 hours and raised £3,780* in support of these organisations.",
            link: oxfam,
            // btnLink: "/en/case-study/oxfam-trailwalker",
            btnLink: "https://www.oxfamapps.org.uk/trailwalker/",
          },
        ]}
      />
      <Researchblock
        quote="'Having diversity of people, who have different ways of doing or thinking about things, is critical to coming up with the right solutions and answers to stay ahead.'"
        Title="Inclusion and diversity conversations"
        Link="/en/diversity"
        className="ga-text-color"
      />
      <GA_paragraph
        id={""}
        title="Making a difference by giving back"
        messagez=" We strive to make a social impact by bringing the strengths of our organisation and the talents and passion of our people together to create a positive impact in society and help make the world a better place for all."
        className="ga-paragraph-title"
        // messageclass="ga-line-height-36"
      />
      <GA_carousel
        id={""}
        content={[
          {
            id: "1",
            title: "Pro bono work ",
            content:
              "Our pro bono efforts aim to build partnerships with a set of non-profit organisations that are aligned to our core values to help unleash their full potential, so they have an even greater impact in the communities they serve.",
            link: pobono,
          },
          {
            id: "2",
            title: "Philanthropy and charity",
            content:
              "We have committed globally to a societal goal of supporting disadvantaged members of society both in actively working to increase access to renewable energy and/or assisting in upgrading infrastructure to improve sustainability, but also where our support can help bring positive change to the regions in which we live and work.",
            link: carouselImage2,
          },
          {
            id: "3",
            title: "Supporting personal activism",
            content:
              "Partners in Performance values celebrating our people’s personal philanthropic and charitable involvement. We believe we have a duty to live by our principles and use our voice and our platform to help bring attention to causes and organisations that do good in the world and are close to the hearts of our people. ",
            link: carouselImage3,
          },
        ]}
      />
      <Stories
        title={"Pro bono success stories"}
        image={probonoworkImage}
        stories={[
          {
            link: "/case-study/redco",
            title:
              " Delivered a 10-year economic development roadmap (EDR) will deploy a total project value of $0.5bn across four strategies.",
            strong: "Siċaŋġu Co:",
          },
          {
            link: "/case-study/naccho",
            title:
              " Delivered a comprehensive business case for Federal funding in the amount of ~$49 million over five years to uplift connectivity and digital capability to improve telehealth opportunities for members.",
            strong: "NACCHO:",
          },
          {
            link: "/case-study/door-of-hope",
            title:
              " Identified and addressed pain points in the donor journey and management systems.",
            strong: "Door of Hope:",
          },
          {
            link: "/case-study/schoolsplus",
            title:
              " Refreshed donor outreach strategy so it is relevant, insightful, and based on data from 39 donors.",
            strong: "Australian Schools Plus:",
          },
          // {
          //   link: "/case-study/rspca",
          //   title:
          //     " Designed a new pricing model which would generate revenue for RSPCA AFS to expand the standards into new areas and reduce admin burden for licensees.",
          //   strong: "RSPCA:",
          // },
          // {
          //   link: "/case-study/amigos-do-bem",
          //   title:
          //     " Amigos do Bem, a non-profit organisation, are one of the largest social projects in Brazil, assisting more than 75,000 people in the hinterland of Alagoas, Pernambuco, and Ceará.",
          //   strong: "AMIGOS DO BEM:",
          // },
        ]}
      />
      {/* <GA_QA
        id={""}
        title={[
          {
            label: "Philanthropy and charity ",
            message:
              "We have committed globally to a societal goal of supporting disadvantaged members of society both in actively working to increase access to renewable energy and/or assisting in upgrading infrastructure to improve sustainability, but also where our support can help bring positive change to the regions in which we live and work.",
          },

          {
            id: 2,
            label: " Supporting personal activism",
            message:
              "We celebrate our people's personal philanthropic and charitable involvement. We believe in using our voice and our platform to help bring attention to causes and organisations that do good in the world and are close to the hearts of our people. ",
          },
        ]}
        message="We strive to bring the strengths of our organisation and the talents and passion of our people together to create positive impact in society and help make the world a better place for all."
        className=""
      /> */}
      {/* <GA_CTA
        id={""}
        title2="Inclusion & Diversity"
        title1="Climate Action"
        Link2="/diversity"
        Link1="/climate-action"
      /> */}
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
