/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import handtree from "../images/handtree.png";
export interface cardModalINFO {
  id: string;
  title?: string;
  headline?: string;
  content?: string;
  link?: string;
}
const tmp = 0;
export const GA_CardModal: React.FC<PropsWithChildren<cardModalINFO>> = ({
  title,
  headline,
}) => {
  let leftClassName: string;
  let rightClassName: string;

  if (!title && !headline) {
    leftClassName = "";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-4 lg:col-span-8 text-center mt-20px lg:mt-0 p1";
  } else if (headline) {
    leftClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
  } else {
    leftClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
  }

  return (
    <section
      data-component="ga_cardModal"
      className={classNames(
        "py-section page-grid max-width z-0 ga-card-session"
      )}
    >
      <>
        <div className="col-span-2"></div>
        <div className="col-span-4 ">{/* <img src={handtree} /> */}</div>
        <div className="col-span-12 ga-card md:col-span-4 col-start-2">
          <div className="col-12 ga-card-gaps-h">
            <h2
              className={classNames(
                leftClassName,
                "lg:row-span-2 ga-font-weight-400 ga-paragraph-title-mt ga-font-style-s28"
              )}
            >
              <b>Our commitment to Net Zero</b>
            </h2>
          </div>
          <div className="col-12 ga-card-gaps-h">
            <p className="p-3 ga-font-style-s16">
              We strive across all aspects of our business to being a driving
              force in helping transition the planet to cleaner energy{" "}
            </p>
          </div>
          <div className="col-12 ga-card-gaps">
            <center>
              <button className="col-start-4 col-span-8 pt-20px sm:pt-none sm:col-start-8 sm:col-span-5 md:col-start-11 md:col-span-3 md:row-start-1 flex self-center ga-card-button ga-font-style-s16">
                <a className="self-center" href="/climate-action">
                  Learn more
                </a>
              </button>
            </center>
          </div>
        </div>
      </>
    </section>
  );
};
